import React, { useState, useContext, useEffect } from "react";
import { Navbar, Container, Nav, Offcanvas } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from '../AuthContext';
import axios from 'axios';

const MainMenu = () => {
  const { isAuthenticated, email, logout } = useContext(AuthContext);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [role, setRole] = useState(localStorage.getItem('userRole') || null);
  const [initialRole, setInitialRole] = useState(localStorage.getItem('userRole') || null);
  const [loadingRole, setLoadingRole] = useState(true);
  const navigate = useNavigate();

  const closeOffcanvas = () => setShowOffcanvas(false);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated && initialRole === null) {
        setLoadingRole(true);
        try {
          const response = await axios.get("https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/users/me");
          setRole(response.data.role);
          setInitialRole(response.data.role);
          localStorage.setItem('userRole', response.data.role);
        } catch (err) {
          console.error(err);
        } finally {
          setLoadingRole(false);
        }
      } else if (!isAuthenticated) {
        setRole(null);
        setInitialRole(null);
        localStorage.removeItem('userRole');
        setLoadingRole(false);
      }
    };

    fetchUserRole();
  }, [isAuthenticated, initialRole]);

  const handleLogout = () => {
    logout();
    navigate('/');
    closeOffcanvas();
    setRole(null);
    setInitialRole(null);
    localStorage.removeItem('userRole');
  };

  const renderKYCButton = () => {
    if (!isAuthenticated) {
      return null; 
    }

    const currentRole = role !== null ? role : initialRole;

    if (currentRole === 'KYC_VERIFIED_USER') {
      return (
        <button className="btn btn-success" disabled>
          KYC Verified
        </button>
      );
    }

    return (
      <a
        className="btn btn-primary"
        href="https://docs.google.com/forms/d/e/1FAIpQLSdRRQNcC6O7AniRMq_WtQ40XQ0-do17cNW1_qJ1z0q-HIUZAA/viewform"
        target="_blank"
        rel="noopener noreferrer"
      >
        KYC Verify
      </a>
    );
  };

  return (
    <>
      <Navbar expand="lg" className="main-menu">
        <Container>
          <Link to="/" className="navbar-brand"></Link>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-lg"
            onClick={() => setShowOffcanvas((prev) => !prev)}
          />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            show={showOffcanvas}
            onHide={closeOffcanvas}
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg"></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="main-menu-list justify-content-center align-items-center flex-grow-1 pe-3">
                <NavLink to="/" className="nav-link" onClick={closeOffcanvas}>
                  Home
                </NavLink>
                <NavLink to="/projects" className="nav-link" onClick={closeOffcanvas}>
                  Launchpad
                </NavLink>
                <NavLink to="/auction" className="nav-link" onClick={closeOffcanvas}>
                  Ongoing Auctions
                </NavLink>
                <NavLink to="/deposit" className="nav-link" onClick={closeOffcanvas}>
                  Deposit for AUSD
                </NavLink>
                <a
                  href="https://app.gitbook.com/o/vDBYLCSELFjJCUXtCfjT/s/3i7GG9G7LsZQI8yrxJPy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                  onClick={closeOffcanvas}
                >
                  Docs
                </a>
                <div className="d-flex align-items-center justify-content-center">
                  {renderKYCButton()}
                </div>
              </Nav>
              <div className="d-flex align-items-center mt-3 mt-lg-0 justify-content-center gap-3">
                {!isAuthenticated ? (
                  <>
                    <Link
                      to="/sign-in"
                      className="btn-nav text-white"
                      onClick={closeOffcanvas}
                    >
                      Sign in
                    </Link>
                    <Link
                      to="/sign-up"
                      className="btn-nav text-black bg-gray"
                      onClick={closeOffcanvas}
                    >
                      Sign Up
                    </Link>
                  </>
                ) : (
                  <>
                    <span className="text-white">{email}</span>
                    <button
                      className="btn-nav text-white"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </>
                )}
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default MainMenu;