import React, { useState, useContext } from "react";
import axios from "axios";
import MainMenu from "../Components/MainMenu";
import placeholder from "../Assets/image/np.png";
import Footer from "../Components/Footer.jsx";
import { AuthContext } from '../AuthContext'; 

const Deposit = () => {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const { email, isAuthenticated } = useContext(AuthContext);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    if (error) setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      window.location.href = "/sign-in";
      return;
    }

    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setError("Please enter a valid amount.");
      return;
    }

    try {
      const storedPassword = localStorage.getItem('authPassword');
      const authHeader = `Basic ${btoa(`${email}:${storedPassword}`)}`;

      const response = await axios.post(
        "https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/balances/ausd-orders",
        null,
        {
          params: {
            ausdAmount: amount,
            currency: "USD",
          },
          headers: {
            "Authorization": authHeader,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data) {
        window.open(response.data, "_blank");
      } else {
        setError("Failed to create order. Please try again.");
      }
    } catch (error) {
      console.error("Error creating order:", error);
      if (error.response && error.response.status === 403) {
        setError("Please verify with KYC first.");
      } else {
        setError("Failed to create order. Please try again.");
      }
    }
  };

  return (
    <>
      <MainMenu />
      <section className="deposit-area pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="deposit-content d-flex">
                <div className="deposit-form mr-4">
                  <div className={`input-list ${error ? 'has-error' : ''}`}>
                    <div className="fs-20 fw-bold">Amount to receive in USD :</div>
                    <input
                      type="text"
                      value={amount}
                      onChange={handleAmountChange}
                      className="form-control mt-2"
                    />
                    {error && <p className="error-message">{error}</p>}
                  </div>
                  <div className="text-start mt-3">
                    <button type="submit" className="btn-nav text-white" onClick={handleSubmit}>
                      Continue
                    </button>
                  </div>
                  <div className="mt-4">
                    <div className="fs-20 fw-bold">Payment Processor :</div>
                    <div className="d-flex align-items-center gap-3 mt-2">
                      <div className="copy-img">
                        <img src={placeholder} alt="Placeholder" className="placeholder-img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fs-14 fw-medium lh-sm deposit-right-text">
                  To receive AUSD the user must first enter the amount in USD they want to deposit and then hit the "Continue" button.
                  You will then be taken to our payment processor NowPayments, where you can select from a list of coins or fiats you wish to pay with.
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  Once you finish the payment process it may take a few minutes for your account to be credited. If your balance is not immediately updated do not panic,
                  as our system may take some time to credit everything to your account. If after about an hour you still did not receive your balance, you may contact us under
                  prosperitytech@proton.me
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Deposit;
