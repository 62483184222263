import React from "react";
import MainMenu from "../Components/MainMenu";
import homeImg from "../Assets/image/banner.jpg";
import Promo from "../Assets/image/promo.png";
import ProjectImg from "../Assets/image/p1.png";
import ProjectLogo from "../Assets/image/pl1.png";
import ProjectImg2 from "../Assets/image/p2.jpg";
import ProjectLogo2 from "../Assets/image/pl2.png";
import ProjectCard from "../Components/ProjectCard";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { TbZoomQuestion } from "react-icons/tb";
import Footer from "../Components/Footer"; 

import Icon1 from '../Assets/image/crypto-icons.png';
import Icon2 from '../Assets/image/Gas-pump.png';
import Icon4 from '../Assets/image/Vetted.png';
import Icon5 from '../Assets/image/Card.png';
import Icon3 from '../Assets/image/Bulb.png';
import Icon6 from '../Assets/image/Community.png';

const Home = () => {
  return (
    <div className="home-wrapper">
      <MainMenu />
      <section className="home-banner">
        <img src={homeImg} alt="" className="banner-img" />
      </section>
      <section className="promo-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center promo-title">
                <div className="fs-48 fw-medium">Welcome to ProsperityPad</div>
                <div className="fs-24 fw-medium pt-2" style={{ color: "#404040" }}>
                  Where prosperity is in abundance
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="promo-content grid-colum-3">
                {promoData.map((item, index) => (
                  <div className="promo-item" key={index}>
                    <div className="icon">{item.icon}</div>
                    <div className="fs-32 fw-semibold text-white pt-2">
                      {item.title}
                    </div>
                    <div className="fs-16 fw-medium text-white pt-3 lh-sm">
                      {item.desc}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="projects-area pt-100 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center promo-title">
                <div className="fs-48 fw-medium">Live & Upcoming Projects</div>
                <div className="fs-24 fw-medium text-gray">
                Prosperity awaits you
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-80">
            <div className="col-lg-12">
              <div className="grid-colum-3">
                {projectData.map((item) => (
                  <ProjectCard
                    key={item.id}
                    cardImg={item.cardImg}
                    cardLogo={item.cardLogo}
                    cardTitle={item.cardTitle}
                    access={item.access}
                    participants={item.participants}
                    blockchain={item.blockchain}
                    raiseData={item.raiseData}
                    raisedAmount={item.raisedAmount}
                    totalAmount={item.totalAmount}
                    progres={item.progres}
                    time={item.time}
                  />
                ))}
              </div>
              <div className="text-center pt-70">
                <Link
                  to="/single-project"
                  className="project-card-btn"
                  style={{
                    height: "40px",
                    minWidth: "173px",
                    fontSize: "16px",
                  }}
                >
                  View all
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="join-area pt-100 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 align-self-center">
              <div className="text-left promo-title mb-md-5">
                <div className="fs-48 fw-medium">How to join</div>
                <div className="fs-24 fw-medium text-gray">
                The Most Unique Launchpad
                </div>
              </div>
            </div>
            <div className="col-lg-8 align-self-center">
              <div className="join-content">
                <div className="video-wrapper">
                  <iframe
                    src="https://www.youtube.com/embed/BmZjL7r0dTA"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-area pt-100 mt-5">
        <div className="container">
          <div className="col-lg-12">
            <div className="text-center promo-title">
              <div className="fs-48 fw-medium">FAQs</div>
              <div className="fs-24 fw-medium text-gray">
                Frequently asked questions
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="faq-content">
                <Accordion defaultActiveKey="">
                  {faqData.map((item, index) => (
                    <Accordion.Item eventKey={item.id} key={index}>
                      <Accordion.Header>
                        <TbZoomQuestion className="qus-zoom" /> {item.title}
                      </Accordion.Header>
                      <Accordion.Body>{item.desc}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        </section>
      <div className="black-brace"></div>
      <Footer/>
    </div>
  );
};

const promoData = [
  {
    icon: <img src={Icon1} alt="Icon 1" className="promo-icon" />,
    title: "Multi-coin launchpad",
    desc: "ProsperityPad allows users to deposit not just one, but multiple different coins to participate in kickstarters and token generation events.",
  },
  {
    icon: <img src={Icon2} alt="Icon 2" className="promo-icon" />,
    title: "Zero gas fees",
    desc: "Once a user creates a deposit from an approved list of coins they will receive AUSD and from there all interactions they make with the launchpad will come with zero gas cost.",
  },
  {
    icon: <img src={Icon3} alt="Icon 3" className="promo-icon" />,
    title: "Uniqueness",
    desc: "ProsperityPad is one of the first launchpads to use an auction lobby style kickstarter model. Please read our doc page that goes into details of how our auction lobby works.",
  },
  {
    icon: <img src={Icon4} alt="Icon 4" className="promo-icon" />,
    title: "Extremely vetted project",
    desc: "The ProsperityPad team is committed to bringing safe and vetted projects to our community. We will ensure that the tokenomics of the project is to the benefit of the community, that the projects are audited, and that the team is credible.",
  },
  {
    icon: <img src={Icon5} alt="Icon 5" className="promo-icon" />,
    title: "Fiat deposit options",
    desc: "One of our goals is to allow our users to utilize multiple fiat deposit options to receive AUSD, which they can utilize to participate in ongoing and upcoming kickstarters/token generation events.",
  },
  {
    icon: <img src={Icon6} alt="Icon 6" className="promo-icon" />,
    title: "Read our docs",
    desc: "We encourage all users of ProsperityPad to take the time to read our docs as it contains very important information such as how the auction lobby works and our terms of service. Not reading our docs is at the disadvantage of the user, so please read the docs by clicking the link at the header of the site that is labeled 'Docs'.",
  },
];

const faqData = [
  {
    id: 0,
    title: "Can anyone use ProsperityPad?",
    desc: "Anyone can signup and complete the KYC on ProsperityPad, however, some projects may be restricted to some users of particular countries due to the regulations of that country.",
  },
  {
    id: 1,
    title: "If I have an issue who do I contact?",
    desc: "With any issues regarding ProsperityPad you can send an email to prosperitytech@proton.me and we will get back to you when we can.",
  },
  {
    id: 2,
    title: "What is AUSD?",
    desc: "AUSD is the USD value of the coins you utilize as a deposit. For example, if you deposit $100 worth of Dogecoin you will receive 100 AUSD.",
  },
  {
    id: 3,
    title: "What coins can be used on ProsperityPad?",
    desc: "We utilize NowPayments as our payment processor, so all coins that are on the NowPayments list are available for deposit on ProsperityPad. You can check out NowPayments coin list here https://nowpayments.io/status-page",
  },
  {
    id: 4,
    title: "How do I verify my account?",
    desc: "To verify your account and to be able to participate in ongoing or upcoming kickstarters/token generation events you must pass the Know Your Customer (KYC) process by signing up through our KYC form. The simplest way of passing the KYC form is by uploading clear and HD pictures/documents.",
  },
  {
    id: 5,
    title: "What is the refund policy?",
    desc: "Our refund policy and a lot of important information is outlined on our doc page. We encourage all users to read our doc page to gain a lot of insight on our terms and conditions, user agreements, as well as our policies.",
  },
];

const projectData = [
  {
    id: 1,
    cardImg: ProjectImg,
    cardLogo: ProjectLogo,
    cardTitle: "Prosperity Pad",
    access: "Restricted areas",
    participants: "TBA",
    blockchain: "BLAST",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 35,
  },
  {
    id: 2,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
  },
  {
    id: 3,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
  },
  {
    id: 4,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
  },
  {
    id: 5,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
  },
  {
    id: 6,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
  },
];

export default Home;
