import React from 'react';

const Home = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path opacity="0.2" d="M10.6875 15.1875V10.6875H7.31251V15.1875H2.81251V8.12108C2.8121 8.04296 2.82817 7.96564 2.85967 7.89416C2.89117 7.82268 2.93739 7.75864 2.99532 7.70624L8.62032 2.59452C8.72286 2.50014 8.85713 2.44775 8.99649 2.44775C9.13585 2.44775 9.27012 2.50014 9.37266 2.59452L15.0047 7.70624C15.0613 7.75969 15.1066 7.82394 15.138 7.89518C15.1694 7.96643 15.1862 8.04323 15.1875 8.12108V15.1875H10.6875Z" fill="currentColor"/>
        <path d="M15.1875 15.1875V8.12108C15.1862 8.04323 15.1694 7.96643 15.138 7.89518C15.1066 7.82394 15.0613 7.75969 15.0047 7.70624L9.37266 2.59452C9.27012 2.50014 9.13585 2.44775 8.99648 2.44775C8.85712 2.44775 8.72285 2.50014 8.62031 2.59452L2.99531 7.70624C2.93738 7.75864 2.89116 7.82268 2.85966 7.89416C2.82816 7.96564 2.81209 8.04297 2.8125 8.12108V15.1875M1.125 15.1875H16.875M10.6875 15.1875V11.25C10.6875 11.1008 10.6282 10.9577 10.5227 10.8522C10.4173 10.7467 10.2742 10.6875 10.125 10.6875H7.875C7.72582 10.6875 7.58274 10.7467 7.47725 10.8522C7.37176 10.9577 7.3125 11.1008 7.3125 11.25V15.1875" stroke="currentColor" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    );
};

export default Home;