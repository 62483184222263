import React, { useState, useContext } from "react";
import axios from "axios";
import MainMenu from "../Components/MainMenu.jsx";
import placeholder from "../Assets/image/np.png";
import Footer from "../Components/Footer.jsx";
import { AuthContext } from '../AuthContext.js'; 

const Deposit = () => {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const { email, isAuthenticated } = useContext(AuthContext);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    if (error) setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      window.location.href = "/sign-in";
      return;
    }

    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setError("Please enter a valid amount.");
      return;
    }

    try {
      const storedPassword = localStorage.getItem('authPassword');
      const authHeader = `Basic ${btoa(`${email}:${storedPassword}`)}`;

      const response = await axios.post(
        "https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/balances/ausd-orders",
        null,
        {
          params: {
            ausdAmount: amount,
            currency: "USD",
          },
          headers: {
            "Authorization": authHeader,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data) {
        window.open(response.data, "_blank");
      } else {
        setError("Failed to create order. Please try again.");
      }
    } catch (error) {
      console.error("Error creating order:", error);
      if (error.response && error.response.status === 403) {
        setError("Please verify with KYC first.");
      } else {
        setError("Failed to create order. Please try again.");
      }
    }
  };

  return (
    <>
      <MainMenu />
      <section className="deposit-area pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="deposit-content d-flex">
                <div className="deposit-form mr-4">
                  
                    <div className="fs-20 fw-bold">Your Deposit was successful!</div>
                    <div className="fs-20 fw-bold">Please wait 1 to 5 fives minutes for you to receive AUSD. You can check your AUSD balance and participate in kickstarters at the "Ongoing Auctions" page.</div>
                  
                  <div className="text-start mt-3">

                  </div>
                  <div className="mt-4">
                    
                    <div className="d-flex align-items-center gap-3 mt-2">

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Deposit;
