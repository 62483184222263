import React from "react";
import { Link } from "react-router-dom";
import {
  TbBrandTelegram,
  TbBrandTwitter,
  TbBrandYoutube,
  TbBrandX,
  TbBrandXing,
} from "react-icons/tb";
import { FaArrowUpLong } from "react-icons/fa6";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row row-gap pb-3 pt-5">
          <div className="col-lg-5">
            <div className="footer-widget-left">
              <Link
                to=""
                className="fs-24 fw-bold text-primary"
                style={{ textDecoration: "none" }}
              >
                About us
              </Link>
              <p className="lh-sm pt-4">
                Prosperitypad is a leading Launchpad for Web3 projects
              </p>
              <p className="lh-sm">
                focusing on innovative technologies such as
              </p>
              <p className="lh-sm">
                DeFi, AI, Big Data, NFTs and Web3 Gaming.
              </p>
              <p className="lh-sm">
                By participating in our kickstarter auction lobby
              </p>
              <p className="lh-sm">
              you increase your chance of reaching prosperity.
              </p>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 pt-4 pt-lg-0">
            <div className="footer-widget-right">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-24 fw-bold text-primary">Company</div>
                <button className="arrow-up" onClick={scrollToTop}>
                  <FaArrowUpLong />
                </button>
              </div>
              <ul className="footer-wed">
                <li>
                  <a href="https://prosperitytech.gitbook.io/prosperitypad/documentation/prosperitypad-terms-of-services" target="_blank" rel="noopener noreferrer" className="lh-sm">Terms of Service</a>
                </li>
                <li>
                  <a href="https://prosperitytech.gitbook.io/prosperitypad/documentation/prosperitypad-privacy-policy" target="_blank" rel="noopener noreferrer" className="lh-sm">Privacy Policy</a>
                </li>
                <li>
                  <a href="https://prosperitytech.gitbook.io/prosperitypad" target="_blank" rel="noopener noreferrer" className="lh-sm">White paper</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="row pb-4 pt-2">
          <div className="col-md-6 align-self-center">
            <div className="fs-16 copy-right">
            © 2024 ProsperityTech Inc. a Panama based Company. All Rights Reserved
            </div>
          </div>
          <div className="col-md-6 align-self-center">
            <div className="social-footer text-end">
              <a href="https://t.me/prosperitytechprojects" target="_blank" rel="noopener noreferrer">
                <TbBrandTelegram />
              </a>
              <a href="https://x.com/prosperitytechX" target="_blank" rel="noopener noreferrer">
                <TbBrandX />
              </a>
              <a href="https://www.youtube.com/@prosperitytechinc" target="_blank" rel="noopener noreferrer">
                <TbBrandYoutube />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
