import React, { useState, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { GoEyeClosed, GoEye } from "react-icons/go";
import axios from "../axios.js";
import Loader from "../Components/Loader";
import { AuthContext } from '../AuthContext';
import signImg from '../Assets/image/sing-img.jpg';
import footer from "../Components/Footer.jsx"

const SignIn = () => {
  const { login } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { register, handleSubmit, formState: { errors }, setError: setFormError } = useForm();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setError('');
    try {
      const apiUrl = "https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/users/me";
      const authHeader = `Basic ` + btoa(`${data.email}:${data.password}`);

      const config = {
        headers: {
          "Authorization": authHeader,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      const response = await axios.get(apiUrl, config);

      if (response.status >= 200 && response.status < 300) {
        login(data.email, data.password);
        navigate("/");
      } else {
        setError("Email or password incorrect, please try again.");
        setFormError("email", { type: "manual", message: "Email or password incorrect, please try again." });
        setFormError("password", { type: "manual", message: "Email or password incorrect, please try again." });
      }
    } catch (error) {
      console.error("Error during sign in:", error);
      if (error.response) {
        setError("Email or password incorrect, please try again.");
        setFormError("email", { type: "manual", message: "Email or password incorrect, please try again." });
        setFormError("password", { type: "manual", message: "Email or password incorrect, please try again." });
      } else {
        setError("An error occurred");
      }
    }
    setLoading(false);
  };

  return (
    <div className="sign-area">
      {loading && <Loader />}
      <div className="sing-input-content">
        <div className="fs-53 fw-bold">Sign in</div>
        <div className="fs-24 pt-4 text-gray">Please login to continue to your account.</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {error && <p className="error-message">{error}</p>}
          <div className={`input-list ${errors.email ? 'has-error' : ''}`}>
            <label className="input-label">Email</label>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
              placeholder="Enter email"
            />
          </div>
          <div className={`input-list password-list ${errors.password ? 'has-error' : ''}`}>
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", { required: "Password is required" })}
              placeholder="Password"
            />
            <span onClick={togglePasswordVisibility}>
              {showPassword ? <GoEye /> : <GoEyeClosed />}
            </span>
          </div>
          <button className="btn-lg btn-primary fs-20 fw-bold" type="submit" disabled={loading}>
            Sign in
          </button>
        </form>
        <div className="fs-24 text-gray text-center pt-lg-5 pt-4">
          Need an account?{" "}
          <Link to="/sign-up" className="fs-24 fw-semibold text-primary">
            Sign up
          </Link>
        </div>
      </div>
      <div className="sing-img">
        <img src={signImg} alt="Sign Rocket" />
      </div>
    </div>
  );
};

export default SignIn;
