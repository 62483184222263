import React from "react";
import signImg from "../Assets/image/sing-img.jpg";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import footer from "../Components/Footer.jsx"

const CheckMail = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  return (
    <div className="sign-area">
      <div className="sing-input-content">
        <div className="fs-53 fw-bold">Successful</div>
        <div className="fs-24 pt-4 text-gray">Please check your emails to continue. Make sure to also check the spam folder.</div>
        <div className="fs-24 text-gray text-center pt-lg-5 pt-4">
        </div>
      </div>
      <div className="sing-img">
        <img src={signImg} alt="Sign Rocket" />
      </div>
    </div>
  );
};

export default CheckMail;
