import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import axios from "../axios.js";
import Loader from "../Components/Loader";
import signImg from '../Assets/image/sing-img.jpg';
import footer from "../Components/Footer.jsx"

const SignUp = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSignUp = async (data) => {
    setLoading(true);
    try {
      const apiUrl = "https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/users/registrations";
      console.log("Sending POST request to:", apiUrl);

      const requestBody = { 
        mail: data.email,
        targetWalletAddress: data.targetWalletAddress
      };

      console.log("Request Body:", requestBody);

      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      });

      console.log("Response:", response);

      if (response.status >= 200 && response.status < 300) {
        navigate(`/check-mail?email=${encodeURIComponent(data.email)}`);
      } else {
        console.error("Registration not successful:", response.data);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sign-area">
      {loading && <Loader />}
      <div className="sing-input-content">
        <div className="fs-53 fw-bold">Sign up</div>
        <div className="fs-24 pt-4 text-gray">Please sign up to continue.</div>
        <form onSubmit={handleSubmit(handleSignUp)}>
          <div className="input-list">
            <label className="input-label">Email</label>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
              placeholder="Enter email"
              required
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}
          </div>
          <div className="input-list">
            <label className="input-label">Wallet Address</label>
            <input
              type="text"
              {...register("targetWalletAddress", { required: "Wallet Address is required" })}
              placeholder="Enter wallet address"
              required
            />
            {errors.targetWalletAddress && <p className="error-message">{errors.targetWalletAddress.message}</p>}
          </div>
          <button className="btn-lg btn-primary fs-20 fw-bold" type="submit">
            Sign up
          </button>
        </form>
        <div className="fs-24 text-gray text-center pt-lg-5 pt-4">
          Already have an account?{" "}
          <Link to="/sign-in" className="fs-24 fw-semibold text-primary">
            Sign in
          </Link>
        </div>
      </div>
      <div className="sing-img">
        <img src={signImg} alt="Sign Rocket" />
      </div>
    </div>
  );
};

export default SignUp;
