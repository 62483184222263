import axios from "axios";


axios.defaults.baseURL = "https://multi-chain-auction-xf35opuyha-ew.a.run.app";


axios.interceptors.request.use((config) => {
  const email = localStorage.getItem("authEmail");
  const password = localStorage.getItem("authPassword");
  if (email && password) {
    const authHeader = "Basic " + btoa(`${email}:${password}`);
    config.headers["Authorization"] = authHeader;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axios;
