import React from 'react';

const Bank = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M2.25 15.75H15.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.25 7.5H15.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.75 4.5L9 2.25L14.25 4.5" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 7.5V15.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15 7.5V15.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 10.5V12.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 10.5V12.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 10.5V12.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default Bank;