import React from 'react';

const MapSearch = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M8.25 13.5L6.75 12.75L2.25 15V5.25L6.75 3L11.25 5.25L15.75 3V10.5" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.75 3V12.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.25 5.25V9" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <circle cx="12.375" cy="13.125" r="1.875" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.875 14.625L15.75 16.5" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default MapSearch;