import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Fire from "../Assets/icons/Fire";

const ProjectCard = ({
  id,
  cardImg,
  cardLogo,
  cardTitle,
  access,
  participants,
  blockchain,
  raiseData,
  raisedAmount,
  totalAmount,
  progres,
}) => {


  return (
    <div className="project-item bg-secondary radius-10" key={id}>
      <div className="project-img radius-10">
        <Link to="/single-project">
          <img src={cardImg} alt="" className="img-fluid" />
        </Link>
        <img src={cardLogo} alt="" className="project-logo" />
        <Link to="/single-project">
          <div className="fs-18 fw-semibold text-black img-text">
            {cardTitle}
          </div>
        </Link>
      </div>
      <div className="project-list">
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-16 fw-semibold text-white">Restricted areas</div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-16 fw-semibold text-white">Participants</div>
          <div className="fs-16 fw-semibold text-primary">{participants}</div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-16 fw-semibold text-white">Blockchain</div>
          <div className="fs-16 fw-semibold text-primary">{blockchain}</div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-16 fw-semibold text-white">Raise date</div>
          <div className="fs-16 fw-semibold text-primary">{raiseData}</div>
        </div>
      </div>
      <div className="raised-amount">
        <div className="fs-16 fw-semibold text-white">Raised amount</div>
        <div className="fs-16 fw-semibold text-white text-800">
          <span className="text-primary">{raisedAmount} AUSD</span>
        </div>
      </div>
      <div className="project-btn d-flex align-items-center justify-content-between">
        <Link to="/single-project" className="project-card-btn">
          View details
        </Link>
      </div>
    </div>
  );
};

export default ProjectCard;
