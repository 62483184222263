import React from "react";
import MainMenu from "../Components/MainMenu";
import ProjectImg from "../Assets/image/p1.png";
import ProjectLogo from "../Assets/image/pl1.png";
import ProjectImg2 from "../Assets/image/p2.jpg";
import ProjectLogo2 from "../Assets/image/pl2.png";
import ProjectCard from "../Components/ProjectCard";
import { Link } from "react-router-dom";
import {
  TbBrandTelegram,
  TbBrandTwitter,
  TbBrandYoutube,
} from "react-icons/tb";
import Footer from "../Components/Footer.jsx"

const Projects = () => {
  return (
    <>
      <MainMenu />
      <section className="banner-area bg-primary">
        <div className="container">
          <div className="row justify-content-center row-gap-4">
            <div className="col-lg-8 align-self-center">
              <div className="banner-text">
                <div className="fs-28 fw-bold text-black">Featured</div>
                <div className="fs-40 fw-black text-black">
                The ProsperityPad Token Kickstarter
                </div>
                <div className="fs-18 fw-semibold text-black lh-sm pt-3">
                The prosperitypad token kickstarter aims to allow individuals from around the world to show their support for the prosperitypad token and the movement that owning the token represents. By supporting the prosperitypad token you support the movement that aims to see the success of a launchpad platform that will bring forth unique, credible, creative, fun, exciting and overall great new digital assets. 
                </div>
                <div className="project-head-btn">
                
                   
                  <a href="https://prosperitypad.com/auction" className="web-btn">
                  <button className="join-raise-btn">Join Raise</button>
                  </a>
                  <div className="head-social">
                    <div className="fs-16 fw-800 text-black">Our Socials</div>
                    <Link to="">
                      <TbBrandTelegram />
                    </Link>
                    <Link to="">
                      <TbBrandTwitter />
                    </Link>
                    <Link to="">
                      <TbBrandYoutube />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="project-banner-card">
                {projectBannerData.map((item) => (
                  <ProjectCard
                    key={item.id}
                    cardImg={item.cardImg}
                    cardLogo={item.cardLogo}
                    cardTitle={item.cardTitle}
                    access={item.access}
                    participants={item.participants}
                    blockchain={item.blockchain}
                    raiseData={item.raiseData}
                    raisedAmount={item.raisedAmount}
                    totalAmount={item.totalAmount}
                    progres={item.progres}
                    time={item.time}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-40 fw-semibold text-white">In progress</div>
                <Link
                  to=""
                  className="project-card-btn"
                  style={{
                    height: "40px",
                    minWidth: "173px",
                    fontSize: "16px",
                  }}
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="card-grid-3">
                {progressData.map((item) => (
                  <ProjectCard
                    key={item.id}
                    cardImg={item.cardImg}
                    cardLogo={item.cardLogo}
                    cardTitle={item.cardTitle}
                    access={item.access}
                    participants={item.participants}
                    blockchain={item.blockchain}
                    raiseData={item.raiseData}
                    raisedAmount={item.raisedAmount}
                    totalAmount={item.totalAmount}
                    progres={item.progres}
                    time={item.time}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-40 fw-semibold text-white">Upcoming</div>
                <Link
                  to=""
                  className="project-card-btn"
                  style={{
                    height: "40px",
                    minWidth: "173px",
                    fontSize: "16px",
                  }}
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="card-grid-3">
                {upcomingData.map((item) => (
                  <ProjectCard
                    key={item.id}
                    cardImg={item.cardImg}
                    cardLogo={item.cardLogo}
                    cardTitle={item.cardTitle}
                    access={item.access}
                    participants={item.participants}
                    blockchain={item.blockchain}
                    raiseData={item.raiseData}
                    raisedAmount={item.raisedAmount}
                    totalAmount={item.totalAmount}
                    progres={item.progres}
                    time={item.time}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="black-brace"></div>
        <Footer />
      </>
    );
  };

const projectBannerData = [
  {
    id: 2,
    cardImg: ProjectImg,
    cardLogo: ProjectLogo,
    cardTitle: "Prosperity Pad",
    access: "Tier 1 & 2",
    participants: "TBA",
    blockchain: "BLAST",
    raiseData: "in progress",
    raisedAmount: "TBA",
    totalAmount: "75,000 ETH",
    progres: 60,
    time: "05 : 12 : 07 : 45",
  },
];

const progressData = [
  {
    id: 1,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
    time: "03 : 00 : 07 : 45",
  },
  {
    id: 2,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
    time: "05 : 12 : 07 : 45",
  },
  {
    id: 3,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
    time: "05 : 12 : 07 : 45",
  },
];
const upcomingData = [
  {
    id: 4,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
  },
  {
    id: 5,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
  },
  {
    id: 6,
    cardImg: ProjectImg2,
    cardLogo: ProjectLogo2,
    cardTitle: "-",
    access: "TBA",
    participants: "TBA",
    blockchain: "TBA",
    raiseData: "TBA",
    raisedAmount: "TBA",
    totalAmount: "",
    progres: 60,
  },
];

export default Projects;
