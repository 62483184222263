import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('authEmail');
    const storedPassword = localStorage.getItem('authPassword');

    if (storedEmail && storedPassword) {
      setIsAuthenticated(true);
      setEmail(storedEmail);
    }
  }, []);

  const login = (email, password) => {
    localStorage.setItem('authEmail', email);
    localStorage.setItem('authPassword', password);
    setIsAuthenticated(true);
    setEmail(email);
  };

  const logout = () => {
    localStorage.removeItem('authEmail');
    localStorage.removeItem('authPassword');
    setIsAuthenticated(false);
    setEmail('');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, email, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
