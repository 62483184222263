import React from "react";
import MainMenu from "../Components/MainMenu";
import images from "../Assets/image/banner-card.png";
import project_logo from "../Assets/image/project-logo.png";
import details from "../Assets/image/details.png";
import Coin from "../Assets/icons/Coin";
import Lock from "../Assets/icons/Lock";
import { Link } from "react-router-dom";
import Home from "../Assets/icons/Home";
import MapSearch from "../Assets/icons/MapSearch";
import Bank from "../Assets/icons/Bank";
import FileDownload from "../Assets/icons/FileDownload";
import { TbBrandTelegram, TbBrandTwitter, TbBrandYoutube } from "react-icons/tb";
import Footer from "../Components/Footer"; 

const SingleProject = () => {
  return (
    <>
      <MainMenu />
      <section className="banner-area bg-primary">
        <div className="container">
          <div className="row justify-content-center row-gap-4">
            <div className="col-lg-8 align-self-center">
              <div className="banner-text">
                <div className="fs-40 fw-black text-black">
                The ProsperityPad Token Kickstarter
                </div>
                <div className="fs-18 fw-semibold text-black lh-sm pt-3">
                The prosperitypad token kickstarter aims to allow individuals from around the world to show their support for the prosperitypad token and the movement that owning the token represents. By supporting the prosperitypad token you support the movement that aims to see the success of a launchpad platform that will bring forth unique, credible, creative, fun, exciting and overall great new digital assets. 
                </div>

              

              </div> 
              
               <a href="https://prosperitypad.com/auction" className="web-btn">
                  <button className="join-raise-btn">Join Raise</button>
                  </a>
            </div>
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="banner-img text-center">
                <img src={images} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="project-details-area">
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="project-details-content radius-5 bg-secondary">
                <img src={project_logo} alt="" />
                <div className="text-content">
                  <div>
                    <div className="fs-24 fw-black text-white">
                    The ProsperityPad Token Kickstarter
                    </div>
                    <div className="fs-16 fw-semibold lh-sm mt-3">
                    In order to participate in the ProsperityPad token auction lobby kickstarter hosted on prosperitypad.com you must first have an account that has undergone the know your customer (KYC) process and has been approved by the prosperitypad.com team. Once you have a KYC approved account you must acquire AUSD by going to the "Deposit for AUSD" section at the top of our website. Once you successfully acquire AUSD you can begin participating in the auction lobby kickstarter. Please note that by signing up to prosperitypad and utilizing our platform you agree to our terms of service and privacy policy.
                    </div>
                  </div>


 {/*                  <div className="d-grid">
                    <div className="project-support-item bg-primary">
                      <div>
                        <div className="fs-16 fw-bold text-black">
                          Total Supply
                        </div>
                        <div className="fs-16 fw-medium text-black">
                          100 Million
                        </div>
                      </div>
                    </div>
                    <div className="project-support-item bg-primary">
                      <div>
                        <div className="fs-16 fw-bold text-black">
                          Initial Supply
                        </div>
                        <div className="fs-16 fw-medium text-black">
                          10 Million
                        </div>
                      </div>
                    </div>
                    <div className="project-support-item bg-primary">
                      <div>
                        <div className="fs-16 fw-bold text-black">FDV</div>
                        <div className="fs-16 fw-medium text-black">
                          $6.23 Million
                        </div>
                      </div>
                    </div>
                    <div className="project-support-item bg-primary">
                      <div>
                        <div className="fs-16 fw-bold text-black">TVL</div>
                        <div className="fs-16 fw-medium text-black">
                          $6.23 Million
                        </div>
                      </div>
                    </div>
                  </div> */}


                </div>
              </div>
            </div>
          </div>
          <div className="row my-3 justify-content-center row-gap-3">
            <div className="col-lg-4 col-md-6">
              <div className="d-flex p-3 pe-4 radius-5 align-items-center justify-content-between bg-secondary">
                <div className="fs-18 fw-bold">
                  <Coin /> Token Price
                </div>
                <div className="fs-18 fw-bold text-primary">TBA</div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex p-3 pe-4 radius-5 align-items-center justify-content-between bg-secondary">
                <div className="fs-18 fw-bold">
                  <Lock /> Daily Release
                </div>
                <div className="fs-18 fw-bold text-primary">
                  3%
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex p-3 pe-4 radius-5 align-items-center justify-content-between bg-secondary">
                <div className="fs-18 fw-bold">
                  <Coin /> Total Raised
                </div>
                <div className="fs-18 fw-bold text-primary">TBA</div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-lg-2 col-md-3">
              <div className="details-side-bar">
                <Link to="" className="list-i">
                  <Home /> About
                </Link>
                <Link to="" className="list-i">
                  <Coin /> Token
                </Link>
                <Link to="" className="list-i">
                  <MapSearch /> Goals
                </Link>
                <Link to="" className="list-i">
                  <Bank /> Supporters
                </Link>

                <div className="details-social bg-primary">
                  <div className="fs18 fw-bold">Social Media</div>

                  <div className="social-icon">
                    <Link to="https://t.me/prosperitytechprojects"> 
                      <TbBrandTelegram />
                    </Link>
                    <Link to="https://x.com/prosperitytechX">
                      <TbBrandTwitter />
                    </Link>
                    <Link to="https://www.youtube.com/@prosperitytechinc">
                      <TbBrandYoutube />
                    </Link>
                  </div>


                  <div className="fs18 fw-bold mt-1"> Website </div>

                  <a href="https://prosperitypad.com" className="web-btn">
                    <div className="social-det"> Prosperitypad.com </div>
                  </a>
                                    

                  <div className="fs18 fw-bold mt-1"> Whitepaper </div>
                  
                  <a href="https://app.gitbook.com/o/vDBYLCSELFjJCUXtCfjT/s/3i7GG9G7LsZQI8yrxJPy/" className="web-btn">
                    <div className="social-det"> View </div>
                  </a>


                </div>

              </div>
            </div>
            <div className="col-lg-10 col-md-9">
              <div className="project-details-text radius-5 bg-secondary">
                <div className="text-center mb-3">
                  <img src={details} alt="" className="img-fluid" />
                </div>
                <div className="fs-16 fw-bold lh-sm">
                This section will cover details about prosperitypad.com (the launchpad platform), prosperitypad token,  the vesting schedule for all those who participate in the auction lobby kickstarter, and other important details. Prosperitypad.com is a one of its kind unique launchpad that has been developed by Prosperitytech Inc (A Panama based company). The overall aim of the prosperitypad launchpad is to be a place where individuals around the world can support promising upcoming digital assets with the belief that these assets can be a net positive for the world. Another goal for the prosperitypad.com launchpad is to be a place where individuals around the world can trust that unique, safe, and vetted projects will come out of. The launchpad itself has been under development for several months due to all of its unique and revolutionary features. Some of the features are listed down below in detail.
                
                
                </div>
                <div className="fs-16 fw-bold lh-sm">

                </div>
                <ul className="details-ul">

                  <li>
                  MultiCoin Deposit Feature-  This is a unique feature that is not present on many launchpads. This feature allows individuals to select from a large list of digital assets and pick which asset they wish to utilize in any auction lobby kickstarter. Essentially, if a user wants to use Bitcoin, Ethereum, Xrp, Polkadot, etc.. to participate in a kickstarter they can. Most launchpads are limited in what users can  use to participate, while our launchpad is not limited in nature.
                  </li>

                  <li>
                  Auction Lobby Model - We cover  in details how our auction lobby works in our documentation page. To keep it short, the auction lobby is a fundraising model that auctions off a specific amount of tokens per day, for a set number of days, users can bid for the tokens, and it creates a fair environment where a user will receive tokens based on their percentage share of the bidding pool. It is the most fair model to have a token generation event. Prosperitypad has perfected the auction lobby model  which gives it a huge advantage over all other launchpads.
                  </li>

                  <li>
                  Zero Gas Fees- Although there is a cost to running the overall prosperitypad.com launchpad, this is not a cost that the end user will have to pay for. The prosperitytech inc / prosperitypad team will cover all transactions that are being performed on the launchpad’s database. This essentially ensures that the end user will not have to pay for gas fees that are typically associated with interacting with blockchain based launchpads.
                  </li>

                  <li>
                  Fiat Deposit Options - Although this is not a feature that is currently live, we hope that in the near future to be a platform that individuals around the world can utilize their debit cards, credit cards, and bank accounts to deposit into kickstarter and fundraising events.With multiple fiat deposit options project creators and founders can potentially see an increase in support for their creative blockchain based ideas.
                  </li>

                </ul>

                <div className="fs-16 fw-bold lh-sm">The ProsperityPad Token:</div>

                <ul className="details-ul">

                  <li>
                  One of the most exciting perks of being a prosperitypad token holder is for the potential to receive future airdrops from projects being hosted on the launchpad. Depending on how these air dropped tokens perform they may have significant value or little to no value. We cannot promise that these air drops will yield profit nor should a supporter expect profit from the works of Prosperitytech Inc, but Prosperitytech inc  chooses to support the prosperitypad token and to be apart of the prosperitypad token community. Similarly to have other major corporations choose to support Bitcoin or Ethereum.
                  </li>

                  <li>
                  The Prosperitypad token itself will be on the Blast Layer 2 Ethereum Network. We believe that the blast layer 2 network offers features that a majority of other blockchains do not offer. We recommend you read up on the features of the blast network here: https://blast.io/en. After the kickstarter is over 100% of the funds raised will be sent to a liquidity pool on a decentralized exchange created by prosperitytech inc. The DEX is called prosperityswap, we will give all officials links when the kickstarter is over.  
                  </li>

                  <li>
                  After the kickstarter for the prosperitypad token is over users will receive their tokens air dropped to them with a vesting schedule. The vesting details is as follows, users will be able to claim 3% of their tokens every 24 hours. So if a user was to receive a total of  1000 tokens for participating in the kickstarter, they will be able to claim 30 tokens per day until they fully claim their 1000 tokens.         
                  </li>




                </ul>

              </div>
            </div>
          </div>


          
        </div>
      </section>
      <div className="black-brace"></div>
      <Footer />
    </>
  );
};

export default SingleProject;
