import React, { useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from '../axios.js';
import { GoEyeClosed, GoEye } from "react-icons/go";
import signImg from "../Assets/image/sing-img.jpg";
import Loader from "../Components/Loader";
import footer from "../Components/Footer.jsx"

const CompleteRegistration = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCompleteRegistration = async (data) => {
    setLoading(true);
    try {
      const apiUrl = `https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/users/registrations/${id}?password=${encodeURIComponent(data.password)}`;
      console.log("Sending PUT request to:", apiUrl);

      const response = await axios.put(apiUrl, {}, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      });

      console.log("Response:", response.data);

      setLoading(false);
      navigate("/sign-in");
    } catch (error) {
      setLoading(false);
      console.error("Error during registration completion:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    }
  };

  return (
    <div className="sign-area">
      {loading && <Loader />}
      <div className="sing-input-content">
        <div className="fs-53 fw-bold">Complete Registration</div>
        <div className="fs-24 pt-4 text-gray">Please set your password to complete the registration. 
        By completing the sign up process and continuing to use any product or service offered through this website,
         you acknowledge you have read and agreed to the Terms of Service and the Privacy Policy.


        </div>
        <form onSubmit={handleSubmit(handleCompleteRegistration)}>
          <div className="input-list password-list">
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", { required: "Password is required" })}
              placeholder="Password"
              required
            />
            <span onClick={togglePasswordVisibility}>
              {showPassword ? <GoEye /> : <GoEyeClosed />}
            </span>
            {errors.password && <p className="error-message">{errors.password.message}</p>}
          </div>
          <button className="btn-lg btn-primary fs-20 fw-bold" type="submit">
            Set password
          </button>
        </form>
      </div>
      <div className="sing-img">
        <img src={signImg} alt="Sign Rocket" />
      </div>
    </div>
  );
};

export default CompleteRegistration;
