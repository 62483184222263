import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import MainMenu from "../Components/MainMenu";
import { Table } from "react-bootstrap";
import { AuthContext } from "../AuthContext";
import '../index.scss';
import Footer from "../Components/Footer.jsx";

const Auction = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [auctionId, setAuctionId] = useState(null);
  const [dayEndsIn, setDayEndsIn] = useState("00:00:00");
  const [currentDay, setCurrentDay] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [totalDepositsSum, setTotalDepositsSum] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unspentAusd, setUnspentAusd] = useState(null);
  const [role, setRole] = useState(localStorage.getItem('userRole') || null);
  const [targetWalletAddress, setTargetWalletAddress] = useState(null);
  const [totalYourDeposits, setTotalYourDeposits] = useState(null);
  const [totalYouReceive, setTotalYouReceive] = useState(null);
  const [isFrozen, setIsFrozen] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const [showSlider, setShowSlider] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [ausdAmount, setAusdAmount] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    const fetchAuctionDetails = async () => {
      try {
        const response = await axios.get("https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/auctions/");
        const auctionData = response.data;
        let selectedAuction = auctionData.find(auction => auction.auctionStatus === "AUCTION_RUNNING");

        if (!selectedAuction) {
          selectedAuction = auctionData.find(auction => auction.auctionStatus === "AUCTION_FROZEN");
          setIsFrozen(true);
        }

        if (selectedAuction) {
          const { id, durationInDays, timeLeft } = selectedAuction;
          setAuctionId(id);
          setTotalDays(durationInDays);
          const daysLeft = parseInt(timeLeft.split("d")[0], 10);
          setCurrentDay(durationInDays - daysLeft);
          setEndTime(Date.now() + parseTimeLeft(timeLeft));
        }
      } catch (err) {
        console.error("Error fetching auction details:", err);
      }
    };

    fetchAuctionDetails();
  }, []);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      if (!auctionId) return;
      try {
        const response = await axios.get(`https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/auctions/${auctionId}`);
        const auctionData = response.data;
        setTotalUsers(auctionData.countInvolvedUsers);
      } catch (err) {
        console.error("Error fetching total users:", err);
      }
    };

    fetchTotalUsers();
  }, [auctionId]);

  useEffect(() => {
    const fetchData = async () => {
      if (!auctionId) return;
      const url = isAuthenticated
        ? `https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/auctions/${auctionId}/my-shares`
        : `https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/auctions/${auctionId}/shares`;
      try {
        const response = await axios.get(url);
        let data = response.data;
        if (!isAuthenticated) {
          data = data.map((item) => ({
            ...item,
            totalUserDepositsDay: item.totalUserDepositsDay === 0 ? "-" : item.totalUserDepositsDay,
            userAuctionTokenDay: item.userAuctionTokenDay === 0 ? "-" : item.userAuctionTokenDay,
          }));
        }

        const sortedData = data.sort((a, b) => b.auctionDay - a.auctionDay);
        setTableData(sortedData);

        const totalDepositsSum = sortedData.reduce((sum, item) => sum + (item.totalDepositsDay !== "-" ? item.totalDepositsDay : 0), 0);
        setTotalDepositsSum(totalDepositsSum);

        const totalYourDeposits = sortedData.reduce((sum, item) => sum + (item.totalUserDepositsDay !== "-" ? item.totalUserDepositsDay : 0), 0);
        setTotalYourDeposits(totalYourDeposits);

        const totalYouReceive = sortedData.reduce((sum, item) => sum + (item.totalUserDepositsDay * item.auctionTokenPerAusdDay), 0);
        setTotalYouReceive(totalYouReceive);

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated, auctionId]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get("https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/users/me");
          setUnspentAusd(response.data.unspentAusd || null);
          setRole(response.data.role || null);
          localStorage.setItem('userRole', response.data.role);
          setTargetWalletAddress(response.data.targetWalletAddress || null);
        } catch (err) {
          if (err.response && err.response.status === 403) {
            setUnspentAusd(null);
          } else {
            console.error(err);
          }
        }
      };

      fetchUserData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (endTime) {
        const timeLeft = endTime - Date.now();
        if (timeLeft > 0) {
          setDayEndsIn(formatTimeLeft(timeLeft));
        } else {
          setDayEndsIn("00:00:00");
          if (currentDay >= totalDays) {
            setDayEndsIn("Day has ended");
          }
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime, currentDay, totalDays]);

  const parseTimeLeft = (timeLeft) => {
    const [daysPart, rest] = timeLeft.split("d ");
    const days = parseInt(daysPart, 10);
    const [hoursPart, minutesPart, secondsPart] = rest.split(/h |m |s/).map(Number);
    const hours = parseInt(hoursPart, 10);
    const minutes = parseInt(minutesPart, 10);
    const seconds = parseInt(secondsPart, 10);

    return ((days * 24 + hours) * 60 + minutes) * 60 * 1000 + seconds * 1000;
  };

  const handleEnterClick = (day) => {
    if (!isAuthenticated) {
      window.location.href = "/sign-in";
    } else {
      setSelectedDay(day);
      setShowSlider(true);
    }
  };

  const handleConfirm = () => {
    setShowConfirmation(true);
  };

  const handleFinalConfirm = async () => {
    try {
      const response = await axios.post(
        `https://multi-chain-auction-xf35opuyha-ew.a.run.app/api/v1/auctions/${auctionId}/${selectedDay}/splits?ausdAmount=${ausdAmount}`,
        null,
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status < 300) {
        alert("Successfully entered the auction! Please refresh the page.");
        setShowSlider(false);
        setShowConfirmation(false);
        setAusdAmount("");
      } else {
        alert("Failed to enter the auction. Please try again.");
      }
    } catch (error) {
      console.error("Error entering auction:", error);
      if (error.response && error.response.status === 403) {
        if (role !== "KYC_VERIFIED_USER") {
          alert("Please verify with KYC first.");
        } else {
          alert("Not enough balance.");
        }
      } else {
        alert("Failed to enter the auction. Please try again.");
      }
    }
  };

  const formatTimeLeft = (timeLeft) => {
    const totalSeconds = Math.floor(timeLeft / 1000);
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const displayValue = (value, showCurrency = true) => (isAuthenticated && value !== null && value !== 0) ? `${value}${showCurrency ? ' AUSD' : ''}` : "-";

  return (
    <>
      <MainMenu />
      <section className="auction-area pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="auction-catagories">
                <div className="auction-catagories-item">
                  <div className="fs-17" style={{ color: "#FEDC90" }}>
                    Total days
                  </div>
                  <div className="fs-26 fw-bold text-white">
                    {tableData.length > 0 ? tableData[0].auctionDay : 0}
                  </div>
                </div>
                <div className="auction-catagories-item">
                  <div className="fs-17" style={{ color: "#FEDC90" }}>
                    Total AUSD deposited
                  </div>
                  <div className="fs-26 fw-bold text-white">
                    {totalDepositsSum} AUSD
                  </div>
                </div>
                <div className="auction-catagories-item">
                  <div className="fs-17" style={{ color: "#FEDC90" }}>
                    Total users
                  </div>
                  <div className="fs-26 fw-bold text-white">
                    {totalUsers} 
                  </div>
                </div>
              </div>
              <div className="auction-table">
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th className="align-top">DAY</th>
                      <th className="align-top">DAILY AVAILABLE TOKENS</th>
                      <th className="align-top">TOTAL DEPOSITS</th>
                      <th className="align-top">YOUR DEPOSITS</th>
                      <th className="align-top">TOKEN PER AUSD</th>
                      <th className="align-top">YOU RECEIVE</th>
                      <th className="align-top">AUCTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.length > 0 ? (
                      tableData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.auctionDay}</td>
                          <td>{loading ? "Loading..." : data.availableTokenDay}</td>
                          <td>{loading ? "Loading..." : data.totalDepositsDay}</td>
                          <td>{loading ? "Loading..." : data.totalUserDepositsDay}</td>
                          <td>{loading ? "Loading..." : data.auctionTokenPerAusdDay}</td>
                          <td>{loading ? "Loading..." : data.userAuctionTokenDay}</td>
                          <td className="p-0">
                            {isFrozen ? (
                              <button className="btn-table btn-collect">
                                Frozen
                              </button>
                            ) : data.auctionDay + 1 > currentDay ? (
                              <button className="btn-table btn-enter" onClick={() => handleEnterClick(data.auctionDay)}>
                                Enter
                              </button>
                            ) : (
                              <button className="btn-table btn-collect">
                                Day has ended
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">{loading ? "Loading..." : "No data found"}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="auction-side-bar">
                <div className={`fs-15 fw-bold day-count ${currentDay >= totalDays ? "day-has-ended" : ""}`}>
                  {isFrozen ? (
                    "Auctions are frozen"
                  ) : currentDay >= totalDays ? (
                    "Auctions ended"
                  ) : (
                    <>Day {currentDay} ends In: {dayEndsIn}</>
                  )}
                </div>
                <div className="fs-15 fw-bold day-count day-open">
                  {isFrozen ? "Auctions are frozen" : currentDay >= totalDays ? "Auctions ended" : "Auctions are open"}
                </div>

                <div className="wallet-profile">
                  <div className="fs-24 fw-bold wallet-12 text-center">
                    Account Info
                  </div>
                  <div className="wallet-item">
                    <label>Balance</label>
                    <div className="text-center text-box">
                      {displayValue(unspentAusd)}
                    </div>
                  </div>
                  <div className="wallet-item">
                    <label>Wallet address</label>
                    <div className="text-center text-box">
                      {displayValue(targetWalletAddress, false)}
                    </div>
                  </div>
                  <div className="wallet-item">
                    <label>Auction entries</label>
                    <div className="text-center text-box">
                      {displayValue(totalYourDeposits)}
                    </div>
                  </div>
                  <div className="wallet-item">
                    <label>Tokens to receive</label>
                    <div className="text-center text-box">
                      {displayValue(totalYouReceive, false)}
                    </div>
                  </div>
                  <div className="wallet-item wallet-12 text-center">
                    <label>KYC Status</label>
                    <div className="d-flex justify-content-center">
                      {!isAuthenticated ? (
                        <a
                          className="btn-nav text-white"
                          href="/sign-in"
                        >
                          Sign in
                        </a>
                      ) : role === "KYC_VERIFIED_USER" ? (
                        <button className="btn btn-success" disabled>
                          Verified
                        </button>
                      ) : (
                        <a
                          className="btn btn-primary"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSdRRQNcC6O7AniRMq_WtQ40XQ0-do17cNW1_qJ1z0q-HIUZAA/viewform"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Verify
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showSlider && (
        <div className="slider">
          <div className="slider-content">
            <div className="slider-header">
              <span>Enter day {selectedDay} with</span>
              <button className="close-button" onClick={() => setShowSlider(false)}>
                &times;
              </button>
            </div>
            <input
              type="text"
              placeholder="AUSD"
              value={ausdAmount}
              onChange={(e) => setAusdAmount(e.target.value)}
              className="form-control mt-2"
            />
            <div className="slider-buttons">
              <button className="btn btn-primary" onClick={handleConfirm}>
                Confirm
              </button>
              <button className="btn btn-secondary" onClick={() => setShowSlider(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="slider">
          <div className="slider-content">
            <div className="slider-header">
              <span>Do you really want to enter day {selectedDay} with {ausdAmount} AUSD?</span>
              <button className="close-button" onClick={() => setShowConfirmation(false)}>
                &times;
              </button>
            </div>
            <div className="slider-buttons">
              <button className="btn btn-primary" onClick={handleFinalConfirm}>
                Yes
              </button>
              <button className="btn btn-secondary" onClick={() => setShowConfirmation(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="black-brace"></div>
      <Footer />
    </>
  );
};

export default Auction;
