import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

const ProtectedRoute = ({ children, isAuthPage = false }) => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated && isAuthPage) {
    return <Navigate to="/" />;
  }

  if (!isAuthenticated && !isAuthPage) {
    return <Navigate to="/sign-in" />;
  }

  return children;
};

export default ProtectedRoute;
