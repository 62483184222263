import React from 'react';

const Coin = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <circle cx="9" cy="9" r="6.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.1 6.74988C10.8232 6.26963 10.304 5.98122 9.75 5.99988H8.25C7.42157 5.99988 6.75 6.67145 6.75 7.49988C6.75 8.3283 7.42157 8.99988 8.25 8.99988H9.75C10.5784 8.99988 11.25 9.67145 11.25 10.4999C11.25 11.3283 10.5784 11.9999 9.75 11.9999H8.25C7.69599 12.0185 7.17685 11.7301 6.9 11.2499" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.65625 4.5C9.65625 4.13756 9.36244 3.84375 9 3.84375C8.63756 3.84375 8.34375 4.13756 8.34375 4.5H9.65625ZM8.34375 6C8.34375 6.36244 8.63756 6.65625 9 6.65625C9.36244 6.65625 9.65625 6.36244 9.65625 6H8.34375ZM9.65625 12C9.65625 11.6376 9.36244 11.3438 9 11.3438C8.63756 11.3438 8.34375 11.6376 8.34375 12H9.65625ZM8.34375 13.5C8.34375 13.8624 8.63756 14.1562 9 14.1562C9.36244 14.1562 9.65625 13.8624 9.65625 13.5H8.34375ZM8.34375 4.5V6H9.65625V4.5H8.34375ZM8.34375 12V13.5H9.65625V12H8.34375Z" fill="currentColor" />
        </svg>
    );
};

export default Coin;