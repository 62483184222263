import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CooldownPage = ({ cooldown, targetRoute }) => {
  const [timeLeft, setTimeLeft] = useState(cooldown);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeLeft <= 0) {
      navigate(targetRoute);
    }
    const timerId = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft, navigate, targetRoute]);

  return (
    <div className="cooldown-page">
      <h1>Too many requests</h1>
      <p>Please wait {timeLeft} seconds.</p>
    </div>
  );
};

export default CooldownPage;
