import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SignUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import SingleProject from "./Pages/SingleProject";
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import Auction from "./Pages/Auction";
import Deposit from "./Pages/Deposit";
import Success from "./Pages/Success";
import CompleteRegistration from "./Pages/CompleteRegistration";
import CheckMail from "./Pages/CheckMail";
import ProtectedRoute from "./Components/ProtectedRoute";
import CooldownPage from "./Pages/CooldownPage";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTo(0, 0);
    }, 0);
  }, [location.pathname, location.search]);
  return children;
};

const useRateLimiter = (ipAddress) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [targetRoute, setTargetRoute] = useState('');

  const MAX_REQUESTS = 2
  const COOLDOWN_TIMES = [2, 5, 10, 20, 30];

  useEffect(() => {
    if (!ipAddress) return;

    const rateLimitData = JSON.parse(localStorage.getItem('rateLimitData')) || {};
    const now = Date.now();

    if (!rateLimitData[ipAddress]) {
      rateLimitData[ipAddress] = {
        requestTimestamps: [],
        cooldownIndex: 0,
        lastRequestedRoute: ''
      };
    }

    const { requestTimestamps, cooldownIndex } = rateLimitData[ipAddress];
    const recentRequests = requestTimestamps.filter(timestamp => now - timestamp < 1000);
    recentRequests.push(now);

    if (recentRequests.length > MAX_REQUESTS) {
      const newCooldownIndex = Math.min(cooldownIndex + 1, COOLDOWN_TIMES.length - 1);
      const newCooldownTime = COOLDOWN_TIMES[newCooldownIndex];
      rateLimitData[ipAddress].cooldownIndex = newCooldownIndex;
      rateLimitData[ipAddress].lastRequestedRoute = window.location.href;
      setCooldown(newCooldownTime);
      setTargetRoute(rateLimitData[ipAddress].lastRequestedRoute);
      setIsBlocked(true);
      setTimeout(() => {
        setIsBlocked(false);
        rateLimitData[ipAddress].requestTimestamps = [];
        localStorage.setItem('rateLimitData', JSON.stringify(rateLimitData));
      }, newCooldownTime * 1000);
    }

    rateLimitData[ipAddress].requestTimestamps = recentRequests;
    localStorage.setItem('rateLimitData', JSON.stringify(rateLimitData));
  }, [window.location.href, ipAddress]);

  return { isBlocked, cooldown, targetRoute };
};

const AppContent = () => {
  const [ipAddress, setIpAddress] = useState("");
  const { isBlocked, cooldown, targetRoute } = useRateLimiter(ipAddress);

  const fetchIP = async () => {
    try {
      const response = await fetch(`https://api.ipify.org`);
      const data = await response.text();
      setIpAddress(data);
    } catch (error) {
      console.error("Failed to fetch IP:", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    fetchIP();
  }, [location]);

  if (isBlocked) {
    return <CooldownPage cooldown={cooldown} targetRoute={targetRoute} />;
  }

  return (
    <div className="App">
      <Routes>
        <Route index element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/single-project" element={<SingleProject />} />
        <Route path="/auction" element={<Auction />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/success" element={<Success />} />
        <Route path="/sign-up" element={<ProtectedRoute isAuthPage={true}><SignUp /></ProtectedRoute>} />
        <Route path="/sign-in" element={<ProtectedRoute isAuthPage={true}><SignIn /></ProtectedRoute>} />
        <Route path="/check-mail" element={<ProtectedRoute isAuthPage={true}><CheckMail /></ProtectedRoute>} />
        <Route path="/complete-registration/:id" element={<ProtectedRoute isAuthPage={true}><CompleteRegistration /></ProtectedRoute>} />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Wrapper>
      <AppContent />
    </Wrapper>
  );
};

export default App;
