import React from 'react';

const Lock = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <rect x="3.75" y="8.25" width="10.5" height="7.5" rx="1.5" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <circle cx="9" cy="12" r="0.75" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 8.25V5.25C6 3.59315 7.34315 2.25 9 2.25C10.6569 2.25 12 3.59315 12 5.25V8.25" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default Lock;